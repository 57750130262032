import {FC} from "react"

import {useNavigateUpBreadcrumbs} from "@frontend/app-container/context"
import {ReactionsWithForm} from "@frontend/components"
import chevron from "@frontend/design/icons/chevron.svg"
import {useTranslation} from "@frontend/i18n"
import type {RemediationPageData} from "@ri2/app/server/routers/getRemediationPageData"
import {FeedbackOnRemediationType} from "@ri2/db/client"
import {hstack} from "@styled-system/patterns"

type Props =
  | {
      caseId: undefined
      remediationPageData: undefined
      feedbackType: undefined
    }
  | {
      caseId: string
      remediationPageData: RemediationPageData
      feedbackType?: FeedbackOnRemediationType
    }

export const MobileBackHeader: FC<Props> = ({
  caseId,
  remediationPageData,
  feedbackType,
}) => {
  const onBack = useNavigateUpBreadcrumbs()
  const t = useTranslation()

  return (
    <div
      className={hstack({
        justifyContent: "space-between",
        marginTop: 24,
        marginBottom: 28,
        paddingX: 16,
        display: {
          base: "flex",
          desktop: "none",
        },
      })}
    >
      <button
        onClick={onBack}
        className={hstack({
          gap: 4,
          textStyle: "button",
          color: "fontBlack",
        })}
      >
        <img src={chevron} alt="" />
        {t("general.back")}
      </button>
      {caseId && remediationPageData && feedbackType && (
        <ReactionsWithForm
          on={
            remediationPageData.type.type === "remediation"
              ? {
                  on: "remediation",
                  caseId,
                  causeId: remediationPageData.type.cause.id,
                  type: "RECOMMENDATION",
                }
              : {
                  on: "peek",
                  caseId,
                  type: "RECOMMENDATION",
                }
          }
          currentFeedback={remediationPageData.feedback[feedbackType]}
        />
      )}
    </div>
  )
}
