import {FC} from "react"
import {ReactSVG} from "react-svg"

import {useNavigateUpBreadcrumbs} from "@frontend/app-container/context"
import {Button, ReactionsWithForm} from "@frontend/components"
import {DesktopAndLargeDesktop} from "@frontend/components/responsive"
import chevronIcon from "@frontend/design/icons/chevron.svg"
import {useTranslation} from "@frontend/i18n"
import {RemediationPageData} from "@ri2/app/server/routers/getRemediationPageData"
import {FeedbackOnRemediationType} from "@ri2/db/client"
import {css, cx} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

import {Title} from "./title"

interface Props {
  title: string
  caseId: string
  remediationPageData: RemediationPageData
  feedbackType?: FeedbackOnRemediationType
  className?: string
  isRecommended?: boolean
  goBack?: boolean
  headerType?: "difm" | "diy"
  isDiy?: boolean
  isDifm?: boolean
}

export const DesktopBackHeader: FC<Props> = ({
  caseId,
  remediationPageData,
  feedbackType,
  className,
  isRecommended,
  goBack,
  headerType,
  title,
}) => {
  const onBack = useNavigateUpBreadcrumbs()
  const t = useTranslation()
  const isDiy = headerType === "diy"
  const isDifm = headerType === "difm"

  return (
    <DesktopAndLargeDesktop>
      <div className={cx(className)}>
        {(isDiy || isDifm) && (
          <div
            className={cx(
              hstack({
                overflowX: "visible",
              }),
              css({minHeight: 24, marginBottom: 24}),
              isDifm
                ? css({justifyContent: "space-between", width: "100%"})
                : "",
            )}
          >
            {goBack && <GoBack onBack={onBack} t={t} />}
            {isDifm && (
              <Feedback
                caseId={caseId}
                remediationPageData={remediationPageData}
                feedbackType={feedbackType}
              />
            )}
          </div>
        )}
        {!isDifm && (
          <div
            className={cx(
              hstack({
                justifyContent: "space-between",
                overflowX: "visible",
              }),
              css({minHeight: 32, height: 32, marginBottom: 24}),
              className,
            )}
          >
            <div
              className={hstack({
                gap: {
                  base: 12,
                  desktop: 16,
                },
              })}
            >
              {goBack && !isDiy && <GoBack onBack={onBack} t={t} />}
              {isDiy && (
                <Title
                  isRecommended={!!isRecommended}
                  title={title}
                  className={css({paddingLeft: 16})}
                />
              )}
            </div>
            <Feedback
              caseId={caseId}
              remediationPageData={remediationPageData}
              feedbackType={feedbackType}
            />
          </div>
        )}
      </div>
    </DesktopAndLargeDesktop>
  )
}

const GoBack: FC<{
  onBack: () => void
  t: ReturnType<typeof useTranslation>
}> = ({onBack, t}) => (
  <div
    className={hstack({
      gridColumn: "1 / 2",
      alignItems: "center",
      display: {
        base: "none",
        desktop: "flex",
      },
    })}
  >
    <Button
      variant="tertiaryBrand"
      leftElement={<ReactSVG src={chevronIcon} />}
      onClick={onBack}
      title={t("general.back")}
    />
  </div>
)

const Feedback: FC<
  Pick<Props, "caseId" | "remediationPageData" | "feedbackType">
> = ({caseId, remediationPageData, feedbackType}) =>
  feedbackType ? (
    <ReactionsWithForm
      on={
        feedbackType && remediationPageData.type.type === "remediation"
          ? {
              on: "remediation",
              caseId,
              causeId: remediationPageData.type.cause.id,
              type: feedbackType,
            }
          : {
              on: "peek",
              caseId,
              type: feedbackType,
            }
      }
      currentFeedback={remediationPageData.feedback[feedbackType]}
      className={css({marginRight: {base: 16, desktop: 0, largeDesktop: 16}})}
    />
  ) : null
