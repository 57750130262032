import {FC} from "react"

import {css, cx} from "@styled-system/css"
import {hstack, vstack} from "@styled-system/patterns"

import {RecommendationBasedOn} from "./based-on"
import {Remediation} from "./remediation"
import {RemediationProps} from "./types"

export const LargeDesktopRemediations: FC<RemediationProps> = ({
  causeOnMessageId,
  caseModelId,
  pageData: {recommendation, feedback, remediationData, type},
  openModal,
}) => (
  <div
    className={vstack({
      backgroundColor: "background.secondary",
      flex: 1,
      gap: 0,
      alignItems: "stretch",
      paddingTop: 24,
      paddingBottom: 16,
      paddingX: 36,
      height: "100%",
      position: "relative",
      width: "100%",
      largeDesktopDown: {
        display: "none",
      },
    })}
  >
    <div
      className={hstack({
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        gap: 16,
        paddingX: 13,
        paddingTop: 24,
      })}
    >
      <div
        className={css({
          display: {
            base: "none",
            largeDesktop: "contents",
          },
        })}
      >
        {remediationData.map((data) => (
          <Remediation
            key={data.type}
            caseId={caseModelId}
            causeOnMessageId={causeOnMessageId}
            data={data}
            className={cx(
              css({
                flexGrow: 1,
                flexShrink: 1,
                flexBasis: 0,
                maxWidth: 350,
                height: "auto",
              }),
              data.isRecommended
                ? css({
                    "@media (max-height: 800px)": {
                      height: 390,
                      maxHeight: 390,
                      minHeight: "auto",
                    },
                    minHeight: 532,
                  })
                : css({
                    "@media (max-height: 800px)": {
                      height: 330,
                      maxHeight: 330,
                      minHeight: "auto",
                    },
                    minHeight: 470,
                  }),
            )}
          />
        ))}
      </div>
    </div>
    {recommendation && (
      <div
        className={hstack({
          justifyContent: "space-between",
        })}
      >
        <div />
        <RecommendationBasedOn
          recommendation={recommendation}
          feedback={feedback.RECOMMENDATION}
          caseId={caseModelId}
          type={type}
          openModal={openModal}
        />
      </div>
    )}
  </div>
)
