import {FC} from "react"

import {ReactionsWithForm} from "@frontend/components"
import {useTranslation} from "@frontend/i18n"
import {css, cx} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

import {RecommendationBasedOn} from "./based-on"
import {Remediation} from "./remediation"
import {RemediationProps} from "./types"

export const MobileRemediations: FC<RemediationProps> = ({
  caseModelId,
  causeOnMessageId,
  pageData: {recommendation, feedback, remediationData, type},
  openModal,
}) => {
  const t = useTranslation()

  const recommendedRemediation = remediationData.find(
    (remediation) => remediation.isRecommended,
  )

  const otherRemediations = remediationData.filter(
    (remediation) => !remediation.isRecommended,
  )

  const headlineStyles = css({
    textStyle: "header.xs",
    color: "text.primary",
  })

  const reactionStyles = css({
    gridColumn: "span 3",
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    minWidth: 283,
    height: 292,
    width: "100%",
  })
  return (
    <div
      className={css({
        display: {
          base: "flex",
          desktop: "none",
        },
        flexDirection: "column",
        paddingBottom: 16,
        paddingTop: 32,
        paddingX: 28,
      })}
    >
      <div
        className={cx(
          recommendedRemediation
            ? hstack({justifyContent: "space-between"})
            : hstack({justifyContent: "flex-end"}),
          css({
            desktop: {
              display: "none",
            },
            marginBottom: 16,
          }),
        )}
      >
        {!!recommendedRemediation && (
          <h4 className={headlineStyles}>
            {t("cause.nextStep.recommendation")}
          </h4>
        )}
        {recommendation && (
          <ReactionsWithForm
            on={
              type.type === "remediation"
                ? {
                    on: "remediation",
                    caseId: caseModelId,
                    causeId: type.cause.id,
                    type: "RECOMMENDATION",
                  }
                : {
                    on: "peek",
                    caseId: caseModelId,
                    type: "RECOMMENDATION",
                  }
            }
            currentFeedback={feedback.RECOMMENDATION}
          />
        )}
      </div>
      {recommendation && (
        <div>
          <RecommendationBasedOn
            recommendation={recommendation}
            feedback={feedback.RECOMMENDATION}
            caseId={caseModelId}
            type={type}
            openModal={openModal}
          />
        </div>
      )}
      <div
        className={hstack({
          display: {base: "flex", largeDesktop: "contents"},
          paddingTop: 24,
          overflowX: "visible",
          maxWidth: "100vw",
          flexWrap: "nowrap",
          flexShrink: 0,
          paddingBottom: 16,
          gap: 16,
          desktopDown: {
            flexDirection: "column",
          },
          "& > div": {
            width: "100%",
          },
        })}
      >
        {recommendedRemediation && (
          <Remediation
            caseId={caseModelId}
            causeOnMessageId={causeOnMessageId}
            data={recommendedRemediation}
            className={reactionStyles}
          />
        )}
        {!!recommendedRemediation && (
          <div
            className={hstack({
              width: "100%",
              justifyContent: "flex-start",

              desktop: {
                display: "none",
              },
            })}
          >
            <h4
              className={cx(
                headlineStyles,
                css({
                  marginTop: 24,
                }),
              )}
            >
              {t("cause.nextStep.additionalOptions")}
            </h4>
          </div>
        )}
        {otherRemediations.map((data) => (
          <Remediation
            key={data.type}
            caseId={caseModelId}
            causeOnMessageId={causeOnMessageId}
            data={data}
            className={reactionStyles}
          />
        ))}
      </div>
    </div>
  )
}
