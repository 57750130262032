import {isNumber} from "lodash"
import {FC} from "react"
import {Trans} from "react-i18next"
import {useNavigate} from "react-router-dom"
import {ReactSVG} from "react-svg"

import homeVector from "@frontend/design/icons/home-vector.svg"
import {useTranslation} from "@frontend/i18n"
import {makePath} from "@frontend/routing"
import {Cost, PriceRanges, Recommendation} from "@ri2/db/client"
import {css, cx} from "@styled-system/css"
import {hstack, iconsColor, vstack} from "@styled-system/patterns"

interface Props {
  caseId: string
  priceRanges: PriceRanges
  recommendation: Recommendation | null
  className?: string
}

export const Peek: FC<Props> = ({
  caseId,
  priceRanges,
  recommendation,
  className,
}) => {
  const navigate = useNavigate()

  const onClick = (): void => {
    navigate(
      makePath({
        name: "cases",
        caseId,
        causeOnMessageId: "peek",
      }),
    )
  }

  const priceBoxClassName = css({
    flexBasis: 0,
    flexGrow: 1,
  })

  const t = useTranslation()

  return (
    <div
      onClick={onClick}
      className={cx(
        vstack({
          borderRadius: 12,
          borderWidth: 1,
          borderColor: "border.brand.secondary",
          backgroundColor: "background.primary",
          alignItems: "stretch",
          cursor: "pointer",
        }),
        className,
      )}
    >
      <p
        className={css({
          paddingX: 16,
          paddingTop: 16,
          textStyle: "captionMedium",
        })}
      >
        <Trans t={t} i18nKey="cases.case.priceRanges.title">
          <span className={css({textStyle: "captionRegular"})} />
        </Trans>
      </p>
      <div
        className={hstack({
          paddingX: 12,
          paddingBottom: 12,
          gap: 8,
          alignItems: "stretch",
        })}
      >
        <PriceBox
          cost={priceRanges.diyCost}
          label={t("cases.case.priceRanges.types.diy")}
          onClick={onClick}
          isRecommended={recommendation?.type === "diyRemediation"}
          className={priceBoxClassName}
        />
        <PriceBox
          cost={priceRanges.difmCost}
          label={t("cases.case.priceRanges.types.difm")}
          onClick={onClick}
          isRecommended={recommendation?.type === "difmRemediation"}
          className={priceBoxClassName}
        />
        <PriceBox
          cost={priceRanges.replaceCost}
          label={t("cases.case.priceRanges.types.replace")}
          onClick={onClick}
          isRecommended={recommendation?.type === "replaceRemediation"}
          className={priceBoxClassName}
        />
      </div>
    </div>
  )
}

interface PriceBoxProps {
  cost: Cost
  label: string
  className?: string
  onClick: () => void
  isRecommended?: boolean
}

const PriceBox: FC<PriceBoxProps> = ({
  cost,
  label,
  isRecommended,
  className,
  onClick,
}) => {
  const t = useTranslation()

  const priceString =
    cost.range && cost.range.low !== cost.range.high
      ? t("general.priceRangeNoCents", {
          bottom: cost.range.low,
          top: cost.range.high,
        })
      : isNumber(cost.cost)
        ? t("general.priceNoCents", {price: cost.cost})
        : null

  return (
    <button
      onClick={onClick}
      type="button"
      className={cx(
        vstack({
          borderRadius: 8,
          backgroundColor: "background.secondary",
          paddingY: 8,
          justifyContent: "center",
          alignItems: "center",
          gap: 0,
          color: "text.tertiary",
          position: "relative",
          overflow: "visible",
          borderWidth: isRecommended ? 1 : 0,
          borderColor: "border.brand.accent",
        }),
        className,
      )}
    >
      <div
        className={css({
          textStyle: "bodyLight",
          textAlign: "center",
        })}
      >
        {priceString}
      </div>
      <div className={css({textStyle: "captionMedium", textAlign: "center"})}>
        {label}
      </div>
      <div
        className={vstack({
          borderRadius: 8,
          position: "absolute",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "background.subtle.brand",
          textStyle: "captionMedium",
          color: "text.brand.accent",
          opacity: 0,

          _hover: {
            opacity: 1,
          },
        })}
      >
        {t("cases.case.priceRanges.learnMore")}
      </div>
      {isRecommended && (
        <div
          className={css({
            position: "absolute",
            top: "-14px",
            right: "-14px",
            zIndex: "causes.sparkle",
          })}
        >
          <ReactSVG
            src={homeVector}
            className={cx(
              iconsColor({color: "background.brand.accent"}),
              css({
                "& svg": {
                  width: "27px",
                  height: "27px",
                },
              }),
            )}
          />
        </div>
      )}
    </button>
  )
}
