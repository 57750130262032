import {FC, useEffect, useState} from "react"
import {ReactSVG} from "react-svg"

import riIcon from "@frontend/design/icons/ri-icon.svg"
import {useTranslation} from "@frontend/i18n"
import type {UiCauses} from "@ri2/app/server/routers/getCasePageData"
import {Peek as PeekModel} from "@ri2/app/server/routers/getPeek"
import {Case, Cost, PriceRanges} from "@ri2/db/client"
import {css, cx} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

import {CausesList} from "./CausesList"
import {NoConfirmedCauses} from "./NoConfirmedCauses"
import {Peek} from "./peek"
import {MORE_AND_VERY_LIKELY_CAUSES} from "./types"
import {UnconfirmedCauses} from "./unconfirmed-causes"

const EMPTY_CAUSES: UiCauses = {
  confirmed: [],
  moreAndVeryLikely: [],
  ruledOut: [],
}

const EMPTY_COST: Cost = {cost: 0, source: {type: "match"}}

interface Props {
  caseModel: Case
  causes?: UiCauses
  peek: PeekModel | null
  className?: string
}

export const Causes: FC<Props> = ({
  caseModel,
  causes = EMPTY_CAUSES,
  peek,
  className,
}) => {
  // Store the last peek so that if it becomes null, we can
  // render the old values as it animates out.
  const [lastPriceRanges, setLastPriceRanges] = useState<PriceRanges>(
    peek?.priceRanges ?? {
      diyCost: EMPTY_COST,
      difmCost: EMPTY_COST,
      replaceCost: EMPTY_COST,
    },
  )

  useEffect(() => {
    if (peek) {
      setLastPriceRanges(peek.priceRanges)
    }
  }, [peek])

  const t = useTranslation()

  return (
    <div
      role="complementary"
      aria-label={t("causes.ariaLabel")}
      className={cx(
        css({
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          overflowY: "auto",
          position: {base: "unset", desktop: "relative"},
          zIndex: 0, // to avoid children appearing on top of the sidebar
          paddingX: 32,
          desktop: {
            paddingRight: 24,
            paddingLeft: 4,
          },
          largeDesktop: {
            paddingLeft: 8,
            paddingRight: 25,
          },
          paddingTop: {
            base: 24,
            desktop: 0,
          },
          maxHeight: {
            base: "101%",
            desktop: "calc(100vh - 128px)",
          },
          desktopDown: {
            backgroundColor: "background.secondary",
          },
        }),
        className,
      )}
    >
      <div>
        <div
          className={css({
            marginTop: {
              desktop: 40,
            },
          })}
        >
          <h2
            className={css({
              fontWeight: 300,
              fontSize: 24,
              lineHeight: "30px",
              color: "text.primary",
              letterSpacing: "-0.01em",
              marginBottom: 24,
              display: {
                base: "none",
                desktop: "block",
              },
            })}
          >
            {t("cases.case.nextStepsTitle")}
          </h2>
          {causes.confirmed.length > 0 ? (
            <>
              <div
                className={hstack({
                  gap: 8,
                  alignItems: "center",
                  justifyContent: "flex-start",
                  paddingY: 8,
                })}
              >
                <ReactSVG
                  src={riIcon}
                  className={css({
                    "& svg": {
                      width: 24,
                      height: 21,
                    },
                  })}
                />
                <h2
                  className={css({
                    textStyle: "bodyLargeMedium",
                    color: "text.primary",
                    letterSpacing: "-0.01em",
                  })}
                >
                  {t("cases.case.likelyCauses")}
                </h2>
              </div>
              <CausesList
                causes={causes.confirmed}
                caseId={caseModel.id}
                causeLikelihood={MORE_AND_VERY_LIKELY_CAUSES}
                causeVariant="confirmed"
              />
            </>
          ) : (
            <NoConfirmedCauses caseModel={caseModel} />
          )}
        </div>
        <UnconfirmedCauses caseModel={caseModel} causes={causes} />
      </div>
      <Peek
        caseId={caseModel.id}
        priceRanges={peek?.priceRanges ?? lastPriceRanges}
        recommendation={peek?.recommendation ?? null}
        className={css({
          position: "sticky",
          bottom: 0,
          zIndex: "causes.peek",
          transition: "0.3s ease",
          opacity: peek ? 1 : 0,
        })}
      />
    </div>
  )
}
